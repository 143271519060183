.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
  margin: 1.2rem auto; /* Center the gallery */
}
.gallery1 {
  grid-template-columns: repeat(1, 1fr);
}
.gallery2 {
  grid-template-columns: repeat(2, 1fr);
}
.gallery3 {
  grid-template-columns: repeat(3, 1fr);
}
.gallery4 {
  grid-template-columns: repeat(4, 1fr);
}

.image-card {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease-out;
}

.image-card img {
  max-width: 100%;
  height: auto; /* Maintain the aspect ratio */
  transition: transform 0.2s ease-out;
}

.image-card:hover img {
  transform: scale(0.98);
}

.image-card::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: background 0.2s ease-out;
  z-index: 1;
  pointer-events: none;
}

.image-card:hover::before {
  background: rgba(0, 0, 0, 0.5); /* slightly darken the image */
}

.image-card::after {
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4em;
  color: white;
  opacity: 0;
  transition: opacity 0.24s ease-out;
  z-index: 2;
  pointer-events: none;
}

.image-card:hover::after {
  opacity: 1;
}

.modal {
  visibility: hidden;
  position: fixed;
  z-index: 1;
  padding-top: 3rem; /* 100px; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; /* Fade in the modal */
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.modal.show {
  visibility: visible;
  opacity: 1;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 6em;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.site-header {
  border-top: 0px !important;
  border-bottom: 0px !important;
}